
import React from "react"
import PropTypes from "prop-types"

import "../styles/layout.scss"

const Layout = ({ children }) => {
  return (
    <>
      <main>
        <div className="inner">
          {children}
        </div>
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
